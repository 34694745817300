<template>
<div class="system-box">
  <img src="~assets/images/public/system.png" alt="">
  <p>系统维护升级中...</p>
</div>
</template>

<script>
export default {
  name: "System",
  created() {
    if(process.env.VUE_APP_SYSTEM == 'on'){
      this.$router.replace('/')
    }
  }
}
</script>

<style lang="scss" scoped>
$r: 750/16/1rem;
.system-box{
  padding-top: 86/$r;
  box-sizing: border-box;
  img{
    width: 462/$r;
    height: 327/$r;
    display: block;
    margin: 0 auto;
  }
  p{
    text-align: center;
    font-size: 24/$r;
    font-weight: 500;
    color: #4D4D4D;
    margin-top: 66/$r;
  }
}
</style>